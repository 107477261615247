import Col from 'react-bootstrap/Col'
import Card from '../landing/elements/Card'
import Image from 'react-bootstrap/Image'
import Link from 'next/link'
import styles from '../../styles/components/IvyStore.module.css'
import Row from 'react-bootstrap/Row'

export default function StoresList ({ stores }) {
  if (stores.length === 0) {
    return null
  }

  return (
    <Row className={'g-3 g-md-4'}>
      {stores.map(storeData => {
        let backgroundImageUrl = storeData.data.background_images?.[0]?.url
        if (!backgroundImageUrl) {
          backgroundImageUrl = '/img/placeholder-store-bg.png'
        }

        return (
          <Col
            key={storeData.id}
            xs={12} md={4}
          >
            <Card className={`position-relative ${styles.imageCard}`}>
              <div className={styles.imageGallery}>
                <Image
                  src={backgroundImageUrl}
                  alt={storeData.name + ' | Ivy Store'}
                  className={'w-100 bg-light br-2'}
                  fluid
                  style={{
                    objectFit: 'cover',
                    height: 200
                  }}
                />
              </div>

              <Link
                href={`/store/${storeData.slug}`}
                className={`text-black ${styles.productLink}`}
              >
                {storeData.name}
              </Link>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
}