import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import Link from 'next/link'
import Card from '../landing/elements/Card'
import StoresList from './StoresList'

export default function SectionStoresByCity ({ stateData, stores = [] }) {
  const storesByCity = {}
  for (const store of stores) {
    if (!storesByCity[store.location.city]) {
      storesByCity[store.location.city] = []
    }
    storesByCity[store.location.city].push(store)
  }

  return (
    <Block>
      <Col xs={12}>
        <Card gap={5}>
          <h2
            className={'fs-4 m-0'}
          >
            Explore Local Couch Deals
          </h2>

          {
            (Array.isArray(stores) && stores.length > 0)
              ? (
                <>
                  {Object.keys(storesByCity).map(citySlug => {
                    const cityData = stateData.cities.find(c => c.slug === citySlug)
                    if (!cityData) {
                      return null
                    }
                    const stores = storesByCity[citySlug]

                    return (
                      <div
                        key={citySlug}
                        className={'d-flex flex-column gap-2'}
                      >
                        <div className={'d-flex gap-3  align-items-center'}>
                          <div className={'fs-4 fw-semibold'}>
                            {cityData.name}
                          </div>

                          <Link
                            href={`/${stateData.slug}/${cityData?.slug}`}
                            className={'btn btn-outline-primary border-0 btn-sm'}
                          >
                            View All
                          </Link>
                        </div>

                        <StoresList stores={stores} />
                      </div>
                    )
                  })}
                </>
              )
              : (
                <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                  <div className="fs-5 text-muted">
                    No stores found
                  </div>
                  <Link
                    href={'/products'}
                    className={'btn btn-success'}
                  >
                    Search couches in other cities
                  </Link>
                </div>
              )
          }
        </Card>
      </Col>
    </Block>
  )
}