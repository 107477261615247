import Block from '../landing/elements/Block'
import Col from 'react-bootstrap/Col'
import ProductCard from './product/ProductCard'
import Link from 'next/link'
import Button from 'react-bootstrap/Button'

export default function SectionProducts ({ stateData, cityData, products = [] }) {
  if (products.length === 0) {
    return null
  }

  return (
    <Block>
      <Col xs={12}>
        <h2 className={'m-0 fs-4'}>
          Today's Pick
        </h2>
      </Col>

      {products.map(item => (
        <Col
          key={item.id}
          xs={6} md={6} lg={4} xl={3}
          className="d-flex flex-column"
        >
          <ProductCard
            item={item}
          />
        </Col>
      ))}

      <Col xs={12}>
        <div className="text-center">
          <Link href={`/products/?state=${stateData.slug}${cityData ? `&city=${cityData.slug}` : ''}`}>
            <Button variant={'light'}>
              View all products
            </Button>
          </Link>
        </div>
      </Col>
    </Block>
  )
}