import { useRouter } from 'next/router'
import SectionIntro from '../../components/ivystore/SectionIntro'
import SectionSofaTypes from '../../components/ivystore/SectionSofaTypes'
import SectionStoresByCity from '../../components/ivystore/SectionStoresByCity'
import SectionAdvantages from '../../components/ivystore/SectionAdvantages'
import SectionFindCouch from '../../components/ivystore/SectionFindCouch'
// import SectionBrands from '../../components/ivystore/SectionBrands'
// import SectionArticles from '../../components/ivystore/SectionArticles'
// import SectionFaqs from '../../components/ivystore/SectionFaqs'
import LandingWrapper from '../../components/landing/elements/LandingWrapper'
import Seo from '../../components/blog/seo'
import { fetchSEO } from '../../lib/blog/api'
import SectionProducts from '../../components/ivystore/SectionProducts'
import baseUrl from '../../lib/base-url'
// import BreadCrumbs from '../../components/ivystore/BreadCrumbs'
import populateSeoWith from '../../lib/populate-seo'
import SectionCouchRemoval from '../../components/ivystore/SectionCouchRemoval'

export const getServerSideProps = async ({ query, res }) => {
  const { stateSlug } = query

  let stateData = null
  let productsData = null

  // Fetch state geography data
  try {
    const geographyResponse = await fetch(`${baseUrl}/api/ivy-store/geography?stateSlug=${stateSlug}`)
    const { data } = await geographyResponse.json()
    stateData = data
  } catch (error) {
    console.error('Failed to fetch state data:', error.message)
  }

  // Fetch stores for this state if it exists
  let stores = []
  if (stateData) {
    try {
      const storesResponse = await fetch(`${baseUrl}/api/ivy-store/stores?stateSlug=${stateSlug}`)
      const { data: storesData } = await storesResponse.json()
      stores = storesData
    } catch (error) {
      console.error('Failed to fetch stores data:', error.message)
    }
  }

  // Fetch 8 latest items for this state
  try {
    const productsResponse = await fetch(`${baseUrl}/api/ivy-store/state-items?stateSlug=${stateSlug}&count=8`)
    const { data } = await productsResponse.json()
    productsData = data
  } catch (error) {
    console.error('Failed to fetch products data:', error.message)
  }

  const seo = await fetchSEO('ivystore-state')
  populateSeoWith(seo, {
    state: stateData?.name,
    min_price_couch_in_city: productsData?.reduce((min, p) => Math.min(min, p.data.price), 500) ?? 500,
  })

  if (!stateData) {
    res.statusCode = 404
  }

  return {
    props: {
      seo,
      stateData,
      productsData,
      stores
    }
  }
}

export default function IvyStoreStatePage ({ seo, stateData, productsData, stores }) {
  const router = useRouter()
  const { stateSlug } = router.query

  if (!stateData) {
    return (
      <>
        <Seo seo={seo}/>
        <LandingWrapper>
          <div className="text-center py-5">
            <h1>Region Not Found</h1>
            <p>The region {stateSlug} was not found.</p>
            {/* TODO: Add similar state suggestions here */}
          </div>
        </LandingWrapper>
      </>
    )
  }

  return (
    <>
      <Seo seo={seo}/>

      {/*<BreadCrumbs />*/}

      <LandingWrapper gap={'4rem'}>
        <SectionIntro
          title={`Find Your Dream Couch up to 70% less than Retail in ${stateData.name}`}
          text={`Discover Affordable, Quality-Checked Sofas in ${stateData.name}. Delivery in 1-3 Days.`}
        />

        <SectionProducts
          products={productsData}
          stateData={stateData}
        />

        <SectionStoresByCity
          stateData={stateData}
          stores={stores}
        />

        <SectionSofaTypes
          stateSlug={stateData.slug}
        />

        <SectionFindCouch
          stateData={stateData}
        />

        <SectionAdvantages
          stateData={stateData}
        />

        <SectionCouchRemoval
          stateData={stateData}
        />

        {/*<SectionBrands*/}
        {/*  brands={brands}*/}
        {/*  stateSlug={stateData.slug}*/}
        {/*/>*/}
        {/*<SectionArticles articles={articles} />*/}
        {/*<SectionFaqs items={faqItems} />*/}
      </LandingWrapper>
    </>
  )
}