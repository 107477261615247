import useIsMobile from '../../../hooks/useIsMobile'
import styles from '../../../styles/components/IvyStore.module.css'
import Image from 'react-bootstrap/Image'
import Link from 'next/link'
import priceFormatter from '../../../lib/priceFormatter'

const ProductCard = ({ item, linkEnabled = true }) => {
  const isMobile = useIsMobile()

  const descriptionString = [
    item.data.brand,
    item.data.color,
    item.data.decorStyle,
    item.data.sofaType,
  ].filter(Boolean).join(' ')

  return (
    <div className={`d-flex flex-column gap-3 position-relative pb-3 ${styles.imageCard}`}>
      <div className={`${styles.imageGallery} ${item.data.images?.length > 1 && styles.imageGalleryHoverable} bg-light br-2`}>
        {/* Placeholder */}
        {!(item.data.images?.length > 0) && (
          <div
            style={{ height: isMobile ? 200 : 300 }}
            className={styles.image}
          >
            <Image
              src={'/img/placeholder-couch.png'}
              alt={`${item.data.title} Image | Ivy Store`}
              className={'w-100 h-100 bg-light'}
              fluid
              style={{ objectFit: 'cover' }}
            />
          </div>
        )}

        {/* Hover Gallery */}
        {item.data.images?.length > 0 && (
          [item.data.images[0], item.data.images?.[1]].filter(Boolean).map((image, index) => (
            <div
              key={index}
              style={{ height: 200 }}
              className={styles.image}
            >
              <Image
                src={image.url}
                alt={`${item.data.title} Image #${index + 1} | Ivy Store`}
                className={'w-100 h-100'}
                fluid
                style={{ objectFit: 'cover' }}
              />
            </div>
          ))
        )}
      </div>
      <div className="d-flex flex-column gap-2">
        {linkEnabled && (
          <Link
            href={`/store/${item.store_data.slug}/${item.slug}`}
            className={`text-black ${styles.productLink}`}
          >
            {item.data.title}
          </Link>
        )}
        {!linkEnabled && (
          <div className={`text-black ${styles.productLink}`}>
            {item.data.title}
          </div>
        )}

        <div className={'fs-5'}>
          {`${priceFormatter.format(item.data.price)} USD`}
        </div>

        {descriptionString && (
          <div className={'text-muted'}>
            {descriptionString}
          </div>
        )}

        {(item.store_data?.location?.city || item.store_data?.location?.state) && (
          <div className={'text-capitalize'}>
            {[item.store_data?.location?.city, item.store_data?.location?.state].filter(Boolean).join(', ')}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductCard